'use client';

import styles from './styles.module.css';
import { useEffect, useRef, useState, useLayoutEffect, useCallback } from 'react';
import { Button } from '../Button';
import { StandardCard } from '../StandardCard';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';
import { ShortGameModelFromFeed } from '@/root/app/[locale]/games/[slug]/types';
import { Badge } from '@/shared/api/arena-data';
import { getValidBadgesBySlug } from '@/shared/utils/utils';

const MAX_VISIBLE_GAMES = 36,
    DEFAULT_ROW_TAKE = 3,
    SCROLLING_ROW_TAKE = 6;

export const StandardCardsGrid = ({
    title,
    category,
    rootCategorySlug,
    games,
    scrollingLoad,
    arenaBadges
}: {
    title?: string;
    category?: string;
    rootCategorySlug?: string;
    games: ShortGameModelFromFeed[];
    scrollingLoad?: boolean;
    arenaBadges?: Badge[];
}) => {
    const arenaData = useUnit($arenaDataStore);
    const [scrollHit, setScrollHit] = useState(false);
    const [page, setPage] = useState(1);
    const [atLastPage, setAtLastPage] = useState(false);
    const gridRef = useRef<HTMLDivElement>(null);
    const [columnsCount, setColumnsCount] = useState(0);

    const getLocal = arenaData?.localeList?.list?.map((l:any) => l.code)?.[0] ?? "en";
    const locale = getLocal ? `/${getLocal}` : '';

    const calculateGrid = useCallback(() => {
        if (!gridRef.current) return;

        const gridWidth = gridRef.current.offsetWidth;
        const firstChild = gridRef.current.children[0] as HTMLElement;

        if (firstChild) {
            const childWidth = firstChild.offsetWidth;
            const computedStyle = window.getComputedStyle(gridRef.current);
            const gap = parseFloat(computedStyle.gap) || 0;
            const columns = Math.floor((gridWidth + gap) / (childWidth + gap));

            setColumnsCount(columns);
        }
    }, []);

    const onScroll = useCallback(() => {
        if (atLastPage) return;

        const heightToBeat = (document.body.scrollHeight - window.innerHeight) / 1.5;
        if (window.scrollY > heightToBeat) setScrollHit(true);
    }, [atLastPage]);

    const loadLastCardObserver = useCallback(() => {
        const lastCard = gridRef.current?.lastElementChild;
        if (!lastCard) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0]?.isIntersecting) setAtLastPage(true);
            },
            { root: gridRef.current, threshold: 1.0 }
        );

        observer.observe(lastCard);
        return observer;
    }, []);

    const loadMore = useCallback(
        (reset = false) => {
            if (reset) return;
            setPage((prevPage) => prevPage + 1);
        },
        [atLastPage]
    );

    const isItemVisible = (index: number) => {
        if (columnsCount === 0) return true; // Show all items if columns haven't been calculated yet
        const take = scrollingLoad ? SCROLLING_ROW_TAKE : DEFAULT_ROW_TAKE;
        const currentRowCount = Math.min(page * take, Math.ceil(games.length / columnsCount));
        const itemRow = Math.floor(index / columnsCount) + 1;
        return itemRow <= currentRowCount;
    };

    useLayoutEffect(() => {
        calculateGrid();

        const resizeObserver = new ResizeObserver(calculateGrid);
        gridRef.current && resizeObserver.observe(gridRef.current);

        return () => resizeObserver.disconnect();
    }, [calculateGrid]);

    useEffect(() => {
        loadMore(true);
    }, [games, loadMore]);

    useEffect(() => {
        const observer = loadLastCardObserver();
        return () => observer?.disconnect();
    }, [gridRef, games, loadLastCardObserver]);

    useEffect(() => {
        if (scrollHit) {
            setScrollHit(false);
            loadMore();
        }
    }, [scrollHit, loadMore]);

    useEffect(() => {
        if (scrollingLoad) addEventListener('scroll', onScroll);
        return () => removeEventListener('scroll', onScroll);
    }, [scrollingLoad, onScroll]);

    const hasHiddenItems = () => games.some((_, index) => !isItemVisible(index));

    return (
        <div className="cardsGrid">
            {title && (
                <div className={styles['title-container']}>
                    <span className={styles.title}>{title}</span>
                    <a className={styles['see-all']} href={`/${rootCategorySlug}/${category}`}>
                        See all
                    </a>
                </div>
            )}
            <div
                ref={gridRef}
                className={styles.results}
                style={
                    {
                        '--page': page,
                        '--max-height': atLastPage ? '100%' : undefined,
                        '--take': scrollingLoad ? SCROLLING_ROW_TAKE : DEFAULT_ROW_TAKE,
                    } as React.CSSProperties
                }
            >
                {games.map((g, index) => (
                    <StandardCard
                        key={index}
                        id={index}
                        slug={g.slug}
                        href={`${locale}/games/${g.slug}`}
                        hoverBlock={{
                            type: arenaData?.layout?.styleOverride?.standardCard?.hoverBlock?.type,
                            title: g.name,
                            description: g.meta?.details,
                            cta: arenaData?.layout?.styleOverride?.standardCard?.hoverBlock?.buttonText ?? 'Play',
                            overrides: arenaData?.layout?.styleOverride?.standardCard?.hoverBlock,
                        }}
                        background={g.meta?.thumbs?.graphic_288x192 || ''}
                        loading={index > MAX_VISIBLE_GAMES ? 'lazy' : 'eager'}
                        style={{ display: isItemVisible(index) ? 'block' : 'none' }}
                        badge={getValidBadgesBySlug(games,g.slug, arenaBadges ?? [])}
                    />
                ))}
            </div>
            {!scrollingLoad && !atLastPage && hasHiddenItems() && (
                <div className={styles['view-more-container']}>
                    <Button
                        variation="outline"
                        className={styles['view-more']}
                        onClick={() => loadMore()}
                        overrides={arenaData?.layout?.styleOverride?.viewMore ?? []}
                        focusable
                    >
                        View more
                    </Button>
                </div>
            )}
        </div>
    );
};
