'use client';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useUnit } from 'effector-react';
import { $allCategoryData, $arenaDataStore } from '@/app.model';
import { HeadlineContext } from '@/components/HeadlineCardsGrid';
import { capitalizeFirstLetter, getCookie } from '@/shared/utils/utils';
import './index.css';
import classNames from 'classnames';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import { Pill } from '@/components/Pill';
import { isServer } from '@/shared/app/isServer';

export const CategoryList = ({ mobileCarousel }: { mobileCarousel?: boolean }) => {
    const { rootCategorySlug, arenaCategories, categories, currentFilter, categoryLinks } = useContext(HeadlineContext);
    const arenaData = useUnit($arenaDataStore);
    const allCategoryPage = useUnit($allCategoryData);
    const [origin, setOrigin] = useState(() => (typeof window !== 'undefined' ? location.origin : ''));
   
    const getLocal = arenaData?.localeList?.list?.map((l) => l.code)?.[0] ?? "en";
    const localeCookie = getLocal ? `/${getLocal}`: '';
    

    const { isDesktop } = useDeviceDetector();

    useEffect(() => {
        setOrigin(typeof window !== 'undefined' ? location.origin : '');
    }, []);

    const createPill = useCallback(
        (category: string, index: number, asSlide: boolean) => {
            const overridesStyling = arenaData?.layout?.styleOverride?.horizontalGenericLinks;
            const categoryLower = category.toLocaleLowerCase();
            const isAllCategory = categoryLower === allCategoryPage.name?.toLocaleLowerCase();
            const isActive = categoryLower === currentFilter?.toLocaleLowerCase();     
            const href = isAllCategory
                ? `${localeCookie}/${rootCategorySlug}`
                : `${localeCookie}/${rootCategorySlug}/${
                      arenaCategories?.find((c) => c.name?.trim().toLocaleLowerCase() === categoryLower)?.slug ??
                      categoryLower.replaceAll(' ', '-')
                  }`;

            const pill = (
               
                <div className={ classNames('ark-ui-pill',{ active: isActive })}>
                    <Pill
                        label={capitalizeFirstLetter(category)}
                        index={index}
                        pillType={categoryLinks || (index === 0 && categories?.length === 2) ? 'anchor' : 'radio'}
                        prefix={'categories'}
                        href={href}
                        target={'_self'}
                        leftImg={
                            isAllCategory
                                ? `${origin}/assets/${
                                      arenaData?.layout?.themeLayout?.theme === 'light' ? 'see_all' : 'see_all_white'
                                  }.svg`
                                : undefined
                        }
                        override_border_color={overridesStyling?.border_color || ''}
                        override_border_color_hover={overridesStyling?.border_color_hover || ''}
                        override_border_color_focus={''}
                        override_border_color_active={overridesStyling?.border_color_active || ''}
                        override_label_color={overridesStyling?.label_color || ''}
                        override_label_color_hover={overridesStyling?.label_color_hover || ''}
                        override_label_color_focus={''}
                        override_label_color_active={overridesStyling?.label_color_active || ''}
                        override_background_color={overridesStyling?.background_color || ''}
                        override_background_color_hover={overridesStyling?.background_color_hover || ''}
                        override_background_color_focus={''}
                        override_background_color_active={overridesStyling?.background_color_active || ''}
                        selected={isActive}
                    />
                </div>
            );
            
            return asSlide ? <SplideSlide key={index}>{pill}</SplideSlide> : <div key={index}>{pill}</div>;
        },
        [arenaCategories, arenaData, currentFilter, categoryLinks, origin, rootCategorySlug, allCategoryPage]
    );

    const pillSlides = useMemo(
        () => categories?.map((category, index) => createPill(category, index, true)),
        [categories, createPill]
    );

    const pillList = useMemo(
        () => categories?.map((category, index) => createPill(category, index, false)),
        [categories, createPill]
    );

    return (
        <>
            {!isDesktop() && (
                <Splide
                    options={{
                        gap: '0.5rem',
                        autoWidth: true,
                        width: '100%',
                        pagination: false,
                        arrows: false,
                        flickPower: 500,
                        flickMaxPages: 3,
                    }}
                    hasTrack={false}
                    className={classNames('category-carousel__slider category-lists-mobile', {
                        'category-lists-mobile-hidden': !mobileCarousel,
                    })}
                >
                    <SplideTrack>{pillSlides}</SplideTrack>
                </Splide>
            )}

            {isDesktop() && (
                <div
                    className={classNames('category-lists category-lists-desktop', {
                        'category-lists-desktop-shown': !mobileCarousel,
                    })}
                >
                    {pillList}
                </div>
            )}
        </>
    );
};
